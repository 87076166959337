
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        



























































































































html:not(.is-clipped) {
    body {
        &.is-mini {
            .is-sticky {
                left: calc(#{ $aside-width-until-widescreen } + 1rem);
            }
        }

        &:not(.is-mini) {
            .is-sticky {
                left: calc(#{ $aside-width-widescreen } + 1rem);
            }
        }
    }

    .is-sticky {
        //noinspection SassScssUnresolvedMixin
        @include touch {
            left: 1rem !important;
        }

        transition: all 250ms ease-in-out 50ms;
        position: sticky;
        z-index: 30;
    }
}
